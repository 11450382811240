<template>
  <div style="width: 100%">
    <multiselect
      id="search"
      ref="multiselect"
      :placeholder="placeholder"
      v-model="selectedOption"
      :multiple="multiple"
      :searchable="true"
      :close-on-select="true"
      :options="list"
      :limit="limit"
      :limitText="limitCount"
      :loading="loader"
      :internal-search="false"
      @search-change="debounceInput"
      :label="label"
      track-by="id"
      @input="returnValue"
      :disabled="disabled"
      class="custom-placeholder custom-input-height"
    >
      <template slot="beforeList">
        <div
          class="newstore"
          v-if="modalename == 'traveaux'"
          v-b-modal.travauxModal
        >
          {{ $t("NEW") }} {{ $t("WORKS") }}
        </div>
        <div
          class="newstore"
          v-if="modalename == 'categorie' && isSuperAdmin"
          v-b-modal.CategorieModal
        >
          {{ $t("NEW") }} {{ $t("CATEGORIES") }}
        </div>
        <div
          class="newstore"
          v-if="modalename == 'client'"
          v-b-modal.clientStoreModal
        >
          {{ $t("NEW") }} {{ $t("CLIENT") }}
        </div>
        <div
          class="newstore"
          v-if="modalename == 'article'"
          v-b-modal.ProductModal
        >
          {{ $t("NEW") }} {{ $t("ARTICLE") }}
        </div>
        <div class="newstore" v-if="modalename == 'piece'" v-b-modal.pieceModal>
          {{ $t("NEW") }} {{ $t("PIECE_") }}
        </div>
        <div
          class="newstore"
          v-if="modalename == 'Forfait'"
          v-b-modal.forfaitModal
        >
          {{ $t("NEW") }} {{ $t("FORFAIT") }}
        </div>
        <div
          class="newstore"
          v-if="modalename == 'MainOeuvre'"
          v-b-modal.mainoeuvreModal
        >
          {{ $t("NEW") }} {{ $t("MAIN_OEUVRE") }}
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="option">
          <span class="option__desc">
            <span class="option__title">
              {{ props.option[label] }}
            </span>
          </span>
        </div>
      </template>

      <template slot="noResult">
        {{ $t("NO_DATA_FOUND") }}
      </template>
    </multiselect>

    <b-form-group v-if="with_default">
      <b-form-checkbox
        v-model="currentDefault"
        @change="filtreList"
        :value="1"
        :unchecked-value="0"
      >
        {{ with_default_lib }}
      </b-form-checkbox>
    </b-form-group>

    <traveauxmodale
      v-if="modalename == 'traveaux'"
      @affichage="changeT($event)"
    ></traveauxmodale>
    <stepReglementBar171
      v-if="modalename == 'mandataire'"
      @affichage="changeMandataire($event)"
    ></stepReglementBar171>
    <stepReglementBar171
      v-if="modalename == 'partenaire'"
      @affichage="changePartenaire($event)"
    ></stepReglementBar171>
    <categoriemodale
      v-if="modalename == 'categorie'"
      @affichage="changecategorie($event)"
      @updateList="filtreList"
    ></categoriemodale>
    <clientmodale
      v-if="modalename == 'client'"
      @affichage="changeclient($event)"
    ></clientmodale>
    <articlemodale
      v-if="modalename == 'article'"
      @affichage="changearticle($event)"
    ></articlemodale>
    <piecemodale
      v-if="modalename == 'piece'"
      @affichage="changepiece($event)"
    ></piecemodale>
    <forfaitmodale
      v-if="modalename == 'Forfait'"
      @affichage="changeforfait($event)"
    ></forfaitmodale>
    <mainoeuvremodale
      v-if="modalename == 'MainOeuvre'"
      @affichage="changemainoeuvre($event)"
    ></mainoeuvremodale>
    <stepInformationBar171
      v-if="modalename == 'certificat_rge'"
      @affichage="changeRGE($event)"
      @updateList="filtreList"
    ></stepInformationBar171>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import traveauxmodale from "@/components/Ajoutmodel/traveauxmodale";
import categoriemodale from "@/components/Ajoutmodel/categoriemodale.vue";
import clientmodale from "@/components/Ajoutmodel/clientmodale.vue";
import articlemodale from "@/components/Ajoutmodel/articlemodale.vue";
import piecemodale from "@/components/Ajoutmodel/piecemodale.vue";
import Forfaitmodale from "@/components/Ajoutmodel/forfaitmodale.vue";
import mainoeuvremodale from "@/components/Ajoutmodel/mainoeuvremodale.vue";
import stepReglementBar171 from "../stepper/stepReglementBar171.vue";
import stepInformationBar171 from "../stepper/editDevis/stepInformationBar171.vue";
export default {
  components: {
    traveauxmodale,
    categoriemodale,
    clientmodale,
    articlemodale,
    piecemodale,
    Forfaitmodale,
    mainoeuvremodale,
    stepReglementBar171,
    stepInformationBar171,
  },
  data() {
    return {
      search: null,
      selectedOption: null,
      article_cat: null,
      rge_cat: null,
      currentDefault: null,
      optionDefault: [
        { value: null, text: "voir publiques" },
        { value: 0, text: "cacher publiques" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getAllcategorieArticle",
      "getOnlineUser",
      "getAllCertificatRges",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },

  props: {
    list: Array,
    loader: Boolean,
    label: String,
    searchFunc: Function,
    selected: Object,
    modalename: String,
    placeholder: { default: "choisir" },
    multiple: Boolean,
    filtre: { type: Object, default: () => {} },
    with_default: Number,
    with_default_lib: String,
    limit: Number,
    disabled: { type: Boolean, default: false },
  },

  methods: {
    ...mapActions([]),

    debounceInput: _.debounce(function (e) {
      const data = {
        ...this.filtre,
        page: 1,
        per_page: 1000,
      };
      if (e) {
        data.search = e;
      }
      if (this.article_cat) data.categorie = this.article_cat.id;
      if (this.rge_cat) data.categorie = this.rge_cat.id;
      if (this.currentDefault) {
        data.with_default = 1;
      }

      this.searchFunc(data);
    }, 500),

    filtreList() {
      const data = {
        ...this.filtre,
        page: 1,
        per_page: 1000,
      };
      if (this.article_cat) data.categorie = this.article_cat.id;
      if (this.rge_cat) data.categorie = this.rge_cat.id;
      if (this.currentDefault) {
        data.with_default = 1;
      }

      this.searchFunc(data);
    },

    returnValue() {
      this.$emit("searchfilter", this.selectedOption);
    },
    changeT(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changeMandataire(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changePartenaire(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changecategorie(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changeRGE(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changeclient(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changearticle(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changepiece(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changeforfait(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    changemainoeuvre(response) {
      this.selectedOption = response;
      this.$emit("searchfilter", this.selectedOption);
    },
    limitCount(count) {
      return `+ ${count} ${this.$t("MORE")}`;
    },
  },

  mounted() {
    if (this.selected) {
      this.selectedOption = this.selected;
    }
    // this.$refs.multiselect.activate()
  },
};
</script>
<style lang="scss" scoped>
::v-deep .multiselect__single {
  font-size: 13px !important;
  height: 30px; 
    line-height: 30px; 
    overflow: hidden; 
    
    text-overflow: ellipsis; 
    box-sizing: border-box; 
}
::v-deep .multiselect__content-wrapper {
  z-index: 20 !important;
}

.dropdown.open {
  z-index: 20 !important;
}
::v-deep .multiselect__tags {
  border: 1px solid #e3e6e9 !important;
  font-size: 13px !important;
  max-height:41px !important;

}
::v-deep .multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
::v-deep .multiselect__placeholder {
  color: #6c757d;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.newstore {
  background: #e4261b;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #e4261b !important;
  }
}
.default {
  background: #28367a;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #28367a !important;
  }
}
.custom-placeholder .multiselect__placeholder {
  font-size: 13px;
}
.custom-input-height .multiselect__input {
  height: 41.1px !important;
}

.custom-placeholder::placeholder {
  font-size: 13px;
}
.custom-input {
  box-shadow: none;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
</style>
