<template>
  <b-modal id="ProductModal" ref="ProductModal" scrollable @hide="close">
    <template #modal-header>
      <h5>{{ $t("Ajouter") }} {{ $t("un") }} {{ $t("PRODUCT") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRO">
      <div class="top">
        <div class="left">
          <div class="star" @click="newPRO.favoris = !newPRO.favoris">
            <svg
              v-if="!newPRO.favoris"
              xmlns="http://www.w3.org/2000/svg"
              width="43.214"
              height="38.942"
              viewBox="0 0 43.214 38.942"
            >
              <g
                id="Icon_material-star"
                data-name="Icon material-star"
                transform="translate(-0.459 -0.543)"
                fill="none"
              >
                <path
                  d="M22.066,30.691l11.783,6.764L30.722,24.707l10.41-8.578L27.423,15.023,22.066,3,16.708,15.023,3,16.129l10.41,8.578L10.283,37.455Z"
                  stroke="none"
                />
                <path
                  d="M 33.84827041625977 37.45508193969727 L 30.72150230407715 24.70670318603516 L 41.13136291503906 16.12920379638672 L 27.42314147949219 15.0230131149292 L 22.06568145751953 3.000002861022949 L 16.70823097229004 15.0230131149292 L 3.000001430511475 16.12920379638672 L 13.40986156463623 24.70670318603516 L 10.28309154510498 37.45508193969727 L 22.06568145751953 30.69101333618164 L 33.84827041625977 37.45508193969727 M 35.37580108642578 39.48506164550781 L 22.06568145751953 31.84407615661621 L 8.755561828613281 39.48506164550781 L 12.28895378112793 25.07883453369141 L 0.45876145362854 15.33102321624756 L 16.03633117675781 14.07398128509521 L 22.06568145751953 0.5431228876113892 L 28.09504127502441 14.07398128509521 L 43.67259979248047 15.33102321624756 L 31.84240913391113 25.07883453369141 L 35.37580108642578 39.48506164550781 Z"
                  stroke="none"
                  fill="#bebebe"
                />
              </g>
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="43.214"
              height="38.942"
              viewBox="0 0 43.214 38.942"
            >
              <g
                id="Icon_material-star"
                data-name="Icon material-star"
                transform="translate(-0.459 -0.543)"
                fill="#FFC400"
              >
                <path
                  d="M22.066,30.691l11.783,6.764L30.722,24.707l10.41-8.578L27.423,15.023,22.066,3,16.708,15.023,3,16.129l10.41,8.578L10.283,37.455Z"
                  stroke="none"
                />
                <path
                  d="M 33.84827041625977 37.45508193969727 L 30.72150230407715 24.70670318603516 L 41.13136291503906 16.12920379638672 L 27.42314147949219 15.0230131149292 L 22.06568145751953 3.000002861022949 L 16.70823097229004 15.0230131149292 L 3.000001430511475 16.12920379638672 L 13.40986156463623 24.70670318603516 L 10.28309154510498 37.45508193969727 L 22.06568145751953 30.69101333618164 L 33.84827041625977 37.45508193969727 M 35.37580108642578 39.48506164550781 L 22.06568145751953 31.84407615661621 L 8.755561828613281 39.48506164550781 L 12.28895378112793 25.07883453369141 L 0.45876145362854 15.33102321624756 L 16.03633117675781 14.07398128509521 L 22.06568145751953 0.5431228876113892 L 28.09504127502441 14.07398128509521 L 43.67259979248047 15.33102321624756 L 31.84240913391113 25.07883453369141 L 35.37580108642578 39.48506164550781 Z"
                  stroke="none"
                  fill="#FFC400"
                />
              </g>
            </svg>
          </div>
          <div class="cam">
            <label for="file_input">
              <svg
                style="cursor: pointer"
                id="Icon_material-photo-camera"
                data-name="Icon material-photo-camera"
                xmlns="http://www.w3.org/2000/svg"
                width="20.456"
                height="18.411"
                viewBox="0 0 20.456 18.411"
              >
                <path
                  id="Tracé_2232"
                  data-name="Tracé 2232"
                  d="M19.746,16.473A3.273,3.273,0,1,1,16.473,13.2,3.273,3.273,0,0,1,19.746,16.473Z"
                  transform="translate(-6.245 -6.245)"
                  fill="#6b6b6b"
                />
                <path
                  id="Tracé_2233"
                  data-name="Tracé 2233"
                  d="M10.16,3,8.288,5.046H5.046A2.052,2.052,0,0,0,3,7.091V19.365a2.052,2.052,0,0,0,2.046,2.046H21.411a2.052,2.052,0,0,0,2.046-2.046V7.091a2.052,2.052,0,0,0-2.046-2.046H18.168L16.3,3Zm3.068,15.342a5.114,5.114,0,1,1,5.114-5.114A5.116,5.116,0,0,1,13.228,18.342Z"
                  transform="translate(-3 -3)"
                  fill="#6b6b6b"
                />
              </svg>
            </label>
            <b-form-file
              v-model="newPRO.img"
              id="file_input"
              style="display: none"
              accept="image/*"
              @change="chargeNewImg"
            >
            </b-form-file>
          </div>
          <svg
            v-if="!newPRO.img"
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="94"
            viewBox="0 0 94 94"
          >
            <path
              id="Icon_material-insert-photo"
              data-name="Icon material-insert-photo"
              d="M98.5,88.056V14.944A10.475,10.475,0,0,0,88.056,4.5H14.944A10.475,10.475,0,0,0,4.5,14.944V88.056A10.475,10.475,0,0,0,14.944,98.5H88.056A10.475,10.475,0,0,0,98.5,88.056ZM33.222,59.333,46.278,75.052,64.556,51.5l23.5,31.333H14.944Z"
              transform="translate(-4.5 -4.5)"
              fill="#dbdbdb"
            />
          </svg>
          <!-- :style="{ 'background-image': `url(${previewNewImage})` }" -->
          <div
            class="image produit"
            v-else
            :style="'background-image: url(' + previewNewImage + ')'"
          >
            <!-- <img v-if="previewNewImage" :src="previewNewImage" /> -->
          </div>
        </div>
        <div class="right">
          <b-form-group :label="$t('prix_achat_ht')" label-for="prix_achat">
            <b-form-input
              id="prix_achat"
              type="number"
              min="1"
              v-model="v$.newPRO.prix_achat.$model"
              :state="validateState('prix_achat')"
              aria-describedby="prix_achat-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.newPRO.prix_achat.$errors"
              id="prix_achat-feedback"
            ></error-handle>
          </b-form-group>
          <div v-if="erreurlist.prix_achat" class="error-message">
            <ul v-if="Array.isArray(erreurlist.prix_achat)">
              <span v-for="(e, index) in erreurlist.prix_achat" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.prix_achat }}</span>
          </div>

          <b-form-group
            :label="$t('UNIT_PRICE') + '*'"
            label-for="prix_vente_HT"
          >
            <b-form-input
              id="prix_vente_HT"
              type="number"
              min="1"
              v-model="v$.newPRO.unit_price.$model"
              :state="validateState('unit_price')"
              aria-describedby="unit_price-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.newPRO.unit_price.$errors"
              id="unit_price-feedback"
            ></error-handle>
            <div v-if="erreurlist.unit_price" class="error-message">
              <ul v-if="Array.isArray(erreurlist.unit_price)">
                <span v-for="(e, index) in erreurlist.unit_price" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.unit_price }}</span>
            </div>
          </b-form-group>

          <b-form-group :label="$t('VAT') + '*'" label-for="tva">
            <b-form-select
              v-model="v$.newPRO.tva.$model"
              :options="options"
              :state="validateState('tva')"
              aria-describedby="tva-feedback"
            ></b-form-select>
            <div v-if="erreurlist.tva" class="error-message">
              <ul v-if="Array.isArray(erreurlist.tva)">
                <span v-for="(e, index) in erreurlist.tva" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.tva }}</span>
            </div>
            <div v-if="v$.newPRO.tva.$error" class="error-message">
              {{ $t("REQUIRED_FIELD") }}
            </div>
          </b-form-group>
          <b-form-group
            style="margin-bottom: 0"
            :label="$t('TYPE_UNITE') + '*'"
            label-for="name"
          >
            <b-form-select
              v-model="newPRO.type_unite"
              :options="optionstype"
              :state="validateState('type_unite')"
              aria-describedby="type_unite-feedback"
            ></b-form-select>
            <div v-if="erreurlist.type_unite" class="error-message">
              <ul v-if="Array.isArray(erreurlist.type_unite)">
                <span v-for="(e, index) in erreurlist.type_unite" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.type_unite }}</span>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="center">
        <b-form-group :label="$t('Nom produit') + '*'" label-for="name">
          <b-form-input
            id="lib"
            v-model="v$.newPRO.lib.$model"
            :state="validateState('lib')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="lib-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newPRO.lib.$errors"
            id="lib-feedback"
          ></error-handle>
          <div v-if="erreurlist.lib" class="error-message">
            <ul v-if="Array.isArray(erreurlist.lib)">
              <span v-for="(e, index) in erreurlist.lib" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.lib }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('MAKER')" label-for="fabricant">
          <b-form-input
            id="fabricant"
            v-model="v$.newPRO.fabricant.$model"
            :state="validateState('fabricant')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="fabricant-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newPRO.fabricant.$errors"
            id="fabricant-feedback"
          ></error-handle>
          <div v-if="erreurlist.fabricant" class="error-message">
            <ul v-if="Array.isArray(erreurlist.fabricant)">
              <span v-for="(e, index) in erreurlist.fabricant" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.fabricant }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('REFERENCE')" label-for="reference">
          <b-form-input
            id="reference"
            v-model="v$.newPRO.ref.$model"
            :state="validateState('ref')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="reference-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newPRO.ref.$errors"
            id="reference-feedback"
          ></error-handle>
          <div v-if="erreurlist.ref" class="error-message">
            <ul v-if="Array.isArray(erreurlist.ref)">
              <span v-for="(e, index) in erreurlist.ref" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.fabrirefcant }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('CATEGORIES') + '*'">
          <searchInput
            modalename="categorie"
            :list="computedAllArticle"
            :loader="getLoadingcategorieArticle"
            label="lib"
            :multiple="true"
            :limit="2"
            :searchFunc="allcategorieArticle"
            @searchfilter="selectCat($event)"
            :placeholder="$t('CATEGORIES')"
            :filtre="{ entreprise: getOnlineUser.entreprise.id }"
          >
          </searchInput>
          <div v-if="erreurlist.categorie_article_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.categorie_article_id)">
              <span
                v-for="(e, index) in erreurlist.categorie_article_id"
                :key="index"
              >
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.categorie_article_id }}</span>
          </div>
          <div v-if="v$.newPRO.categorie_article.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>

        <b-form-group :label="$t('DESC')" label-for="Description">
          <b-form-textarea
            id="desc"
            v-model="newPRO.desc"
            :placeholder="$t('Description')"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <div v-if="erreurlist.desc" class="error-message">
            <ul v-if="Array.isArray(erreurlist.desc)">
              <span v-for="(e, index) in erreurlist.desc" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.desc }}</span>
          </div>
        </b-form-group>
      </div>
    </form>

    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button
          variant="success"
          @click="addPRO"
          :disabled="getProductLoading"
        >
          <div class="block-spinner">
            {{ $t("SAVE") }}
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  numeric,
  minValue,
  maxValue,
  requiredIf,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchInput: () => import("@/components/ui/searchInput"),
    errorHandle,
  },

  data() {
    return {
      newPRO: {
        lib: null,
        fabricant: null,
        ref: null,
        prix_achat: 1,
        unit_price: 1,
        img: null,
        tva: 0,
        desc: null,
        favoris: null,
        categorie: "TypePrestation.Fourniture",
        type_unite: "unitaire",
        categorie_article: [],
      },
      erreurlist: {
        lib: null,
        fabricant: null,
        ref: null,
        prix_achat: null,
        unit_price: null,
        entreprise_id: null,
        tva: null,
        desc: null,
        favoris: null,
        categorie: null,
        type_unite: null,
        categorie_article: null,
      },
      previewNewImage: null,
      options: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      optionstype: [
        { value: "m2", text: "m2" },
        { value: "ml", text: "ML" },
        { value: "h", text: "h" },
        { value: "forfait", text: this.$t("TypePrestation.Forfait") },
        { value: "unitaire", text: this.$t("unit_unitaire") },
      ],
      per_page: 1000,
      page: 1,
      errorS: null,
      box: "",
    };
  },
  validations() {
    return {
      newPRO: {
        lib: { required, maxLength: maxLength(200) },
        fabricant: { maxLength: maxLength(100) },
        ref: { maxLength: maxLength(100) },
        type_unite: { required },
        prix_achat: {
          numeric,
          maxValue: maxValue(100000),
        },
        unit_price: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100000),
        },
        tva: { required, numeric },
        categorie_article: { requiredIf: requiredIf(this.isSuperAdmin) },
      },
    };
  },
  methods: {
    ...mapActions([
      "store_product",
      "all_entreprises",
      "allcategorieArticle",
      "visibilite_prestation",
      "store_visibilite_categorie_article",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newPRO[name];
      return $dirty ? !$error : null;
    },

    chargeNewImg(e) {
      const file = e.target.files[0];
      this.previewNewImage = URL.createObjectURL(file);
    },

    async addPRO() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.erreurlist = {
        lib: null,
        fabricant: null,
        ref: null,
        prix_achat: null,
        unit_price: null,
        img: null,
        tva: null,
        desc: null,
        favoris: null,
        type_unite: null,
        categorie_article_id: null,
      };
      this.errorS = null;
      var bodyFormData = new FormData();
      bodyFormData.append("lib", this.newPRO.lib);
      bodyFormData.append("fabricant", this.newPRO.fabricant);
      for (
        let index = 0;
        index < this.newPRO.categorie_article.length;
        index++
      ) {
        bodyFormData.append(
          "categorie_article_id[" + index + "]",
          this.newPRO.categorie_article[index].id
        );
      }
      bodyFormData.append("ref", this.newPRO.ref);
      bodyFormData.append(
        "prix_achat",
        this.newPRO.prix_achat ? this.newPRO.prix_achat : 0
      );
      bodyFormData.append(
        "unit_price",
        this.newPRO.unit_price ? this.newPRO.unit_price : 0
      );
      bodyFormData.append("tva", this.newPRO.tva);
      bodyFormData.append("type_unite", this.newPRO.type_unite);
      bodyFormData.append("desc", this.newPRO.desc);
      bodyFormData.append("img", this.newPRO.img);
      bodyFormData.append(
        "favoris",
        this.newPRO.favoris === null ? "false" : "true"
      );
      bodyFormData.append("lang", "fr");

      // this.visibilite_prestation().then(() => {
      //   var list = this.getAllprestationV;
      //   this.newPRO.categorie_article.forEach((element) => {
      //     if (!list.find((item) => item.id == element.id)) {
      //       list.push(element);
      //     }
      //   });
      //   this.store_visibilite_categorie_article({
      //     categorie_article_id: list.map((item) => item.id),
      //   });

      // });
      this.store_product(bodyFormData)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    selectCat(cat) {
      this.newPRO.categorie_article = cat;
    },

    close(bv) {
      if (this.$refs["ProductModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },

    resetModal() {
      this.errorS = null;
      this.$refs["ProductModal"].hide();
      this.newPRO = {
        lib: null,
        file: null,
        fabricant: null,
        reference: null,
        prix_achat: 1,
        unit_price: 1,
        img: null,
        tva: 0,
        desc: null,
        favoris: null,
        categorie_article_id: null,
        categorie: null,
        type_unite: "unitaire",
      };
      this.erreurlist = {
        lib: null,
        fabricant: null,
        ref: null,
        prix_achat: null,
        unit_price: null,
        img: null,
        tva: null,
        desc: null,
        favoris: null,
        type_unite: null,
        categorie_article_id: null,
      };
      this.previewNewImage = null;
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
  },
  mounted() {
    if (!this.isSuperAdmin) this.visibilite_prestation();
  },
  computed: {
    ...mapGetters([
      "getAllcategorieArticle",
      "getAllentreprises",
      "getOnlineUser",
      "getProductLoading",
      "getLoadingcategorieArticle",
      "getAllprestationV",
    ]),
    computedAllArticle() {
      var list = [];
      if (!this.isSuperAdmin) {
        this.getAllcategorieArticle.forEach((cat) => {
          if (this.getAllprestationV.find((item) => item.id == cat.id))
            list.push(cat);
        });
      } else {
        list = this.getAllcategorieArticle;
      }
      return list;
    },
    computedAllentreprises() {
      return this.getAllentreprises;
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
  },
};
</script>

<style lang="scss" scoped>
.image.produit {
  height: 175px;
  width: 175px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f5f6f8;
}
</style>
