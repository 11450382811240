<template>
    <div class="">
      <div class="row my-4">
        <!-- First Column -->
        <div class="col-md-6 col-lg-6 col-sm-12">
          <form>
            <div class="background-white mt-3">
              <div class="title">
                <div class="position">Règlement</div>
              </div>
            </div>
            <div class="center borderPointier mt-3 p-3">
                <div class="">
           
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>
                    <b-form-select
  v-model="regulateurs.moyens_paiement"
  :options="optionspaiement"
  text-field="text"
  value-field="value"
  required
  class="form-input custom-placeholder custom-input"
>
<template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{  $t("MOD_PAYMENT") }} --</b-form-select-option
                  >
                </template>
</b-form-select>

            </div>
          </div>
            
            <b-form-group :label="$t('start_work') ">
          <b-form-input
            type="date"
            v-model="regulateurs.debut_travaux"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
     
        <b-form-group :label="$t('end_work') ">
          <b-form-input
            type="date"
            v-model="regulateurs.fin_travaux"
            required
            class="form-input custom-placeholder custom-input"
          ></b-form-input>
        </b-form-group>
            </div>
          </form>
        </div>
      


        <!-- Second Column -->
        <div class="col-md-6 col-lg-6 col-sm-12">
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">Mandataire</div>
          </div>
        </div>
        <form>
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group label="Mandataire">
              <searchInput
                :list="getMondataireEntitesExternes"
                :loader="getLoadingEntiteExterne"
                label="nom"
                 placeholder="$t('Select_agent')"
                :searchFunc="allEntitesExternes"
                @searchfilter="selectMondataire($event)"
                :filtre="{ entreprise: getOnlineUser.entreprise.id }"
                :with_default="0"
                class="form-input custom-placeholder custom-input"
              >
              </searchInput>  </b-form-group>

         
          </div>

          <div class="borderPointier mt-3 pt-3 px-3">
            <div class="background-white">
              <div class="title">
                <div class="position">Partenaire</div>
              </div>
            </div>
            

          
            <b-form-group label="Partenaire">
              <searchInput
                :list="getPartenaireEntitesExternes"
                :loader="getLoadingEntiteExterne"
                label="nom"
                placeholder="$t('Select_partner')"
                :searchFunc="allEntitesExternes"
                @searchfilter="selectPartenaire($event)"
                :filtre="{ entreprise: getOnlineUser.entreprise.id  }"
                :with_default="0"
                class="form-input custom-placeholder custom-input"
              >
              </searchInput>   </b-form-group>

        
          </div>
       
        </form>
      </div>
      </div>

    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  components:{
    searchInput: () => import("@/components/ui/searchInput"),
  },
  props: {
    regulateurs: {
      type: Object,
      required: true,
     
    },
    
  },
    data() {
    return {
      moyens_paiement: 'financement',
      partenaire:{},
      mondataire:{},
      messages: "Veuillez remplir tout les champs correctement !",
      showErrorAlert: false,
      showSuccessAlert: false,
      optionspaiement: [
        { value: "financement", text: this.$t("FINANCEMENT") },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        
      ],
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
    };
  }, 
  computed: {
    ...mapGetters([
    "getAllEntitesExternes",
    "getOnlineUser",
    "getMondataireEntitesExternes",
    "getPartenaireEntitesExternes",
    "getLoadingEntiteExterne",
  ]),
  }, 

  methods:{
    ...mapActions(["allEntitesExternes"]),
  async fetchData() {
    await this.allEntitesExternes({ page: 1, per_page: 10, search: '', type: '' });

  },
  selectMondataire(mondataire) {
    this.mondataire = { ...mondataire };
    this.$emit('update-mondataire', this.mondataire);
  },
  selectPartenaire(partenaire) {
    this.partenaire = { ...partenaire };
  
    this.$emit('update-partenaire', this.partenaire);
  },
 
  }  
}
</script>
<style scoped >

.borderPointier {
  border: 2px dashed  #24316d;
  position: relative;
}
.input-style {

  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}


.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem; 
  left: 0; 
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color:#24316d;
  position: relative;
  z-index: 2;
  margin: 0; 
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem; 
}
.custom-placeholder::placeholder {
  font-size: 13px; 
}
.custom-input {
  box-shadow: none; 
}
.form-input {
  font-size: 13px; 
  height: 41.1px !important;
}
</style>