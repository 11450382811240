<template>
  <div class="">
    <form @submit.prevent="submitForm">
      <div class="row mt-4">
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Information technique</div>
            </div>
          </div>

          <div class="center borderPointier mt-3 p-3">
          
            <b-form-group label="Type de pompe ">
              <b-form-select
                v-model="systemeChauffageData.denomination_temperature"
              
                :options="optionsType"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-select>
            </b-form-group>

            <div class="fieldset mt-3">
              <div class="legend mb-2">Coeffcient de performance (COP)</div>
              <b-form-input
                type="number"
                v-model="systemeChauffageData.coefficient_performance"
                placeholder="Coeffcient "
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-2">
                Efficacité énergétique saisonniére en % (calculer selon le
                réglément (EU))
              </div>
              <b-form-input
                type="number"
                v-model="systemeChauffageData.efficacite"
                placeholder="Efficacité"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>

            <div class="fieldset mt-3">
              <div class="legend mb-2">Surface chauffée(PAC)</div>
              <b-form-input
                type="number"
                v-model="systemeChauffageData.surface_chauffee"
                placeholder="Surface"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </div>
         
            <b-form-group label="Régulateur">
              <b-form-select
  v-model="systemeChauffageData.regulateur_classer"
  :placeholder="$t('Régulateur')"
  :options="optionsTypeClient"
  class="form-input custom-placeholder custom-input"
>
</b-form-select>

            </b-form-group>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">Matériel depose</div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
            <b-form-group label="Équipement">
              <b-form-input
                type="text"
                v-model="systemeChauffageData.equipement_deposee"
                placeholder="Équipement"
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Type de combustible">
              <b-form-input
                type="text"
                placeholder=" type "
                v-model="systemeChauffageData.type_energie_equipement_deposee"
                required
                class="form-input custom-placeholder custom-input"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {},
      form2: {
        nom: null,
      },
      systemeChauffageData: this.initializeSystemeChauffageData(),
      systemAdd: this.devisObject?.data?.devis?.systeme_chauffage === null,
      optionsType: [
        { value: "moyenne", text: this.$t("Moyenne") },
        { value: "haute", text: this.$t("Haute") },
        { value: "basse", text: this.$t("Basse") },
      ],
      optionsTypeClient: [
        { value: "I", text: this.$t("I") },
        { value: "II", text: this.$t("II") },
        { value: "III", text: this.$t("III") },
        { value: "IV", text: this.$t("IV") },
        { value: "V", text: this.$t("V") },
      ],
    };
  },

  props: {
    devisObject: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions(["editSystemeChauffage", "storeSystemeChauffage"]),

    initializeSystemeChauffageData() {
      const systemeChauffage =
        this.devisObject?.data?.devis?.systeme_chauffage || {};
      return {
        denomination_temperature:
          systemeChauffage.denomination_temperature || "",
        coefficient_performance:
          parseInt(systemeChauffage.coefficient_performance) || "",
        efficacite: parseInt(systemeChauffage.efficacite) || "",
        cp: systemeChauffage.cp || "",
        surface_chauffee: parseInt(systemeChauffage.surface_chauffee) || "",
        regulateur_classer: systemeChauffage.regulateur_classer,
        equipement_deposee: systemeChauffage.equipement_deposee,
        type_energie_equipement_deposee:
          systemeChauffage.type_energie_equipement_deposee,
      };
    },
    async submitForm() {
      const response = await this.storeSystemeChauffage(
        this.systemeChauffageData
      );
      this.devisObject.data.devis.systeme_chauffage_id = response.data.id;

      this.emitUpdate();
      return response;
    },
    async edit() {
      if (this.systemAdd) {
        await this.submitForm();

        this.emitUpdate();
      } else {
        const systeme = {
          ...this.systemeChauffageData,
          id: this.devisObject.data?.devis?.systeme_chauffage.id,
        };

        const res = await this.editSystemeChauffage(systeme);
        this.emitUpdate();
        return res;
      }
    },
    emitUpdate() {
      this.$emit("updateDevisObject", this.devisObject);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
</style>
